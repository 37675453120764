import * as S from "./style";
import React, { Component } from "react";
import { Helmet } from "react-helmet"

export default class BannerFooter extends Component {
 
  render() {

  const html2 = '';

    return (
      
      <S.BannerFooter>
 

      </S.BannerFooter>
    );
  }
}
