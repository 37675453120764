
import styled from 'styled-components';

export const ContainerFluid = styled.div`
    display: flex;
    flex-direction: column;
`;
export const ContainerTop = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    position: relative;
    top: 63px;
    height: auto;
    width: 100%;
    @media (max-width: 767px) {
        width: 99%;
        padding: 0 0.5%;
    }
`;
export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    position: relative;
    top: 3px;
    height: auto;
    width: 100%;
    @media (max-width: 767px) {
        width: 99%;
        padding: 0 0.5%;
    }
`;
