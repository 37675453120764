import styled from "styled-components";

export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  margin: 7px 0;
  width: 70%;
  /* border: 1px solid green; */
  @media (max-width: 767px) {
    height: 60px;
    width: 99%;
  }
`;
export const Input = styled.input`
  display: block;
  max-height: 32px;
  min-height: 32px;
  width: 70%;
  border-radius: 5px;
  padding: 0 15px;
  margin: 15px 7px;
  border: 1px solid white;
  color: black;
`;
export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 32px;
  min-height: 32px;
  max-width: 120px;
  border-radius: 5px;
  padding: 0 15px;
  margin: 30px;
  border: 1px solid white;
  color: black;
  @media (max-width: 767px) {
    margin: 3px;
  }
`;
export const Btn = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 0px ;
  border-radius: 10px;
  /* border: 1px solid white; */
`;
export const Icon = styled.img`
  width: 24px;
  height: 24px;
  /* border: 1px solid blue; */
`;
export const Response = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin: 7px;
  height: auto;
  width: 70%;
  margin-bottom: 90px;
  /* border: 1px solid blue; */
  @media (max-width: 767px) {
    padding: 19px 0;
    width: 99%;
  }
`;
export const ResponseErro = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16px;
  margin: 15px 0px;
  height: auto;
  width: 100%;
  color: red;
  /* border: 1px solid blue; */
  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export const ResponseTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 17px;
  height: auto;
  width: 60%;
  color: white;
  font-weight: 600;
  font-size: 22px;
  /* border: 1px solid blue; */
  @media (max-width: 767px) {
    flex-direction: column;
    width: 99%;
    left: 0.5%;
    font-size: 16px;

  }
`;

export const ViewTracking = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  position: relative;
  padding: 15px 0;
  width: 98%;
  max-width: 790px;
  height: auto;
  /* border: 1px solid white; */
  z-index: 999;
`;
export const Guia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35px;
  height: 95px;
`;
export const LineIcon = styled.img`
  width: 1px;
  height: 100%;
  margin-left: -5px;
  margin-top: 5px;
  /* border: 1px dashed white; */
`;
export const LineType = styled.img`
  width: 28px;
  height: 28px;
  /* border: 1px solid blue; */
`;
export const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 75%;
  height: auto;
  padding: 0px 3px;
  margin: 0 0.5%;
  /* border: 1px solid blue; */
  @media (max-width: 767px) {
    width: 85%;
  }
`;
export const Datas = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 100%;
  padding: 0px 3px;
  /* border: 1px solid blue; */
  @media (max-width: 767px) {
    width: 10%;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin: 3px 0;
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
`;
export const DatasP = styled.p`
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin: 0 3px;
  @media (max-width: 767px) {
    font-size: 12px !important;
  }
`;

