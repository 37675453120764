import React, { useState, useEffect } from "react";

import * as S from "./style";
import line from "../../assets/icons/line.png";
import truck from "../../assets/icons/truck2.png";
import car from "../../assets/icons/car.png";
import encomenda from "../../assets/icons/encomenda4.png";
import checked from "../../assets/icons/encomendaCkecked.png";
import sort from "../../assets/icons/sort-two.png";

import BannerCenter from "../Banner/BannerCenter";
import apiRegister from "../../service/apiSendRegister";

export default function Search() {
  const [codigo, setCodigo] = useState("");
  const [data, setData] = useState([]);
  const [msgErro, setMsgErro] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePress = () => {

    const regex = /^[a-zA-Z]{2}\d{9}[a-zA-Z]{2}$/i;
    const isValid = regex.test(codigo);
    if (isValid) {
      handleSubmit();
      setMsgErro("");
    } else {
      setMsgErro("O código é inválido");
    }
  };
  const handleSubmit = async () => {
    const apiSendRegister = new apiRegister();
    const values = { codigo: codigo, key: process.env.REACT_APP_API_KEY_ACCESS };

    const sendApi = await apiSendRegister.sendCode("/site/search/", values);
    const { statusObject, history } = sendApi;
    setData(history);
    console.log(sendApi);
  };
  const formatDataHora = (dataString) => {
    if (dataString) {
      const regex = /^\d{2}\/\d{2}\/\d{4}, \d{2}:\d{2}:\d{2}$/;
      //const str = "19/01/2024, 19:39:45";

      if (regex.test(dataString)) {
        //console.log("A string está no formato correto");
        const dataArray = dataString.split(", ");
        const dia = dataArray[0].split("/")[0];
        const mes = dataArray[0].split("/")[1];
        const ano = dataArray[0].split("/")[2];
        const hora = dataArray[1].split(":")[0];
        const minuto = dataArray[1].split(":")[1];
        const segundo = dataArray[1].split(":")[2];
        let dataDay = `${dia}/${mes}`;
        if (dia == new Date().getDate()) {
          dataDay = `Hoje`;
        }
        return `${dataDay} ${hora}:${minuto}:${segundo}`;
      }
    }
    console.log("A string não está no formato correto");
    return dataString;
  };
  const formatEventosDesc = (eventos) => {
    const results = eventos.sort((a, b) => {
      const dataA = new Date(a.createdAt);
      const dataB = new Date(b.createdAt);
      return dataB - dataA;
    });
    return results;
  };

  const formatEventosAsc = (eventos) => {
    const results = eventos.sort((a, b) => {
      const dataA = new Date(a.created);
      const dataB = new Date(b.created);
      return dataA - dataB;
    });
    return results;
  };

  const formatData = (dataString) => {
    if (dataString) {
      const dataInteira = new Date(dataString);
      const dataFormatada = dataInteira.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        //year: 'numeric'
      });
      return dataFormatada;
    }
  };

  const formatHour = (hora) => {
    if (hora) {
      const match = hora.match(
        /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/
      );
      const horaString = match[1].substring(11, 16);
      return horaString;
    }
  };

  const imageStatus = (obj) => {
    const postado = /\bpostado\b/g;
    const transito = /\btrânsito\b/g;
    const entrega = /\bsaiu para entrega\b/g;
    const entregue = /\bentregue\b/g;

    if (obj.match(postado)) {
      return encomenda;
    } else if (obj.match(transito)) {
      return truck;
    } else if (obj.match(entrega)) {
      return car;
    } else if (obj.match(entregue)) {
      return checked;
    }
    return encomenda;
  };

  const Historico = data && data.length > 0? formatEventosDesc(data) : false;

  return (
    <>
      <S.Form>
        <S.Input
          type="text"
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
        />

        <S.Button onClick={handlePress}>Buscar</S.Button>
      </S.Form>
      <S.ResponseErro>{msgErro}</S.ResponseErro>

      <S.ResponseTitle>
        {/* {dataFormated && <BannerCenter />}
        {screenWidth > 767 && dataFormated && <BannerCenter />} */}
        Historico
        {/* {screenWidth > 767 && dataFormated && <BannerCenter />}
        {screenWidth > 767 && dataFormated && <BannerCenter />} */}

      </S.ResponseTitle>

      <S.Response>
        {Historico &&
          Historico.map((item) => (
            <S.ViewTracking key={item.index}>
              <S.Guia>
                <S.LineType src={imageStatus(item.title)} resizeMode="cover" />
                <S.LineIcon src={line} resizeMode="cover" />
              </S.Guia>
              <S.Info>
                <S.Text>{item.title} </S.Text>
                {item.origin && <S.Text>De: {item.origin} </S.Text>}
                {item.destine && <S.Text>Para: {item.destine} </S.Text>}

                <S.Text>{item.infoAdditional}</S.Text>
              </S.Info>
              <S.Datas>
                <S.DatasP>{item.created && formatData(item.created)}</S.DatasP>
                <S.DatasP>{item.created && formatHour(item.created)}</S.DatasP>
              </S.Datas>
            </S.ViewTracking>
          ))}
      </S.Response>
    </>
  );
}
