import Encrypt from "../config/Cripto";
export default class apiRegister {
  constructor() {
    this.encrypt = new Encrypt();
    this.BASE_URL = "https://rastreiopro.com.br:3023";
  }
  async sendCode(url, params) {
    if (!params) {
      console.log("faltando params");
    }
    try {
      const messageEncripted = await this.encrypt.update(params);
      const msgReplace = messageEncripted.replace(/\//g, "rastreio");
      return fetch(`${this.BASE_URL}${url}${msgReplace}`)
        .then((response) => response.json())
        .then((json) => {
          return json;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  headers(h) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...h,
    };
    return headers;
  }
}
