import React, { Component, useState, useEffect } from "react";
import Preloader from "../components/Pre";
import Navbar from "../components/Menu/Navbar";
import Home from "./Home/Home";
import Footer from "./Footer";
import Resume from "./Resume/ResumeNew";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import BannerRight from "../components/Banner/BannerRight";
import BannerLeft from "../components/Banner/BannerLeft";
import BannerFooter from "../components/Banner/BannerFooter";
import * as S from "./style";

import "./style.css";
import "./app.css";
import "bootstrap/dist/css/bootstrap.min.css";
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
    };
    this.Load();
  }

  Load = () => {
    const timer = setTimeout(() => {
      this.setState({ load: false });
    }, 1200);

    return () => clearTimeout(timer);
  };
  render() {
    const { load } = this.state;
    return (
      <Router>
        <Preloader load={load} />
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tracking" element={<Resume />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <BannerLeft />
          <BannerRight />
          <BannerFooter />
          <Footer />
        </div>
      </Router>
    );
  }
}
