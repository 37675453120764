import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../assets/home-main.svg";
import Particle from "../../components/Particle";
import Search from "../../components/Search/Search";
import * as S from "./style";

function Home() {
  return (
    <section>
      <S.ContainerFluid>
        <Particle />
        <S.ContainerTop>
          <Search />
        </S.ContainerTop>
      </S.ContainerFluid>
    </section>
  );
}

export default Home;
