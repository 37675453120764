
import styled from 'styled-components';

export const Banner = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    /* border: 1px solid white; */
    width: auto;
    height: auto;
`;
export const BannerFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position: relative;
    bottom: 15px;
    left: 15%;
    height: auto;
    min-height: 190px;
    padding: 9px 0;
    width: 70%;
    color: #fff;
    /* border: 1px solid white; */
    @media (max-width: 767px) {
        width: 99%;
        left: 0.5%;
        min-height: 90px;
        bottom: 0;

    }
`;
export const BannerCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position: relative;
    height: 250px;
    width: 250px;
    margin: 9px;
    color: #fff;
    @media (max-width: 767px) {
        width: 100%;
        height: auto;
        min-height: 250px;
        margin: 9px 0;
    }
    /* border: 1px solid white; */
`;
export const BannerLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position: fixed;
    top: 65px;
    left: 35px;
    height: auto;
    min-height: 720px;
    width: 120px;
    color: #fff;
    /* border: 1px solid red; */
    @media (max-width: 767px) {
        display: none;
    }
`;
export const BannerRight = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    position: fixed;
    top: 65px;
    right: 35px;
    height: auto;
    min-height: 720px;
    width: 120px;
    color: #fff;
    /* border: 1px solid red; */
    @media (max-width: 767px) {
        display: none;
    }
`;